import React, { useState, useCallback, useRef } from 'react';

// Move analysisTypes outside the component to prevent recreation
const analysisTypes = ['Warren Buffett', 'William ONeil', 'Benjamin Graham', 'James OShaughnessy', 'Peter Lynch'];

// Mapping for backend keys
const analysisTypeKeys = {
  'Warren Buffett': 'Buffett',
  'William ONeil': 'ONeil',
  'Benjamin Graham': 'Graham',
  'James OShaughnessy': 'OShaughnessy',
  'Peter Lynch': 'Lynch'
};

// Get progress bar color and label based on percentage
const getProgressInfo = (percentage) => {
  if (percentage >= 75) {
    return {
      color: 'bg-green-500 dark:bg-green-600',
      label: 'Strong Match',
      textColor: 'text-green-600 dark:text-green-500'
    };
  }
  if (percentage >= 50) {
    return {
      color: 'bg-yellow-500 dark:bg-yellow-600',
      label: 'Moderate Match',
      textColor: 'text-yellow-600 dark:text-yellow-500'
    };
  }
  return {
    color: 'bg-red-500 dark:bg-red-600',
    label: 'Weak Match',
    textColor: 'text-red-600 dark:text-red-500'
  };
};

// InfoCardWarren component
const InfoCardWarren = ({ title, loading, conditionsMet, totalConditions, error }) => {
  // Calculate percentage here
  const percentage = totalConditions > 0 ? (conditionsMet / totalConditions) * 100 : 0;
  const { color, label, textColor } = getProgressInfo(percentage);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-3 sm:p-4 h-full">
      <div className="flex flex-col h-full">
        <h3 className="text-sm sm:text-base font-semibold text-gray-800 dark:text-gray-200 mb-2">
          {title}
        </h3>
        {loading ? (
          <div className="flex-1 flex flex-col items-center justify-center space-y-2">
            <div className="animate-spin rounded-full h-6 w-6 border-2 border-gray-300 dark:border-gray-600 border-t-blue-600"></div>
            <span className="text-sm text-gray-500 dark:text-gray-400">Analyzing...</span>
          </div>
        ) : error ? (
          <div className="flex-1 flex items-center justify-center text-red-500 text-sm sm:text-base">
            {error}
          </div>
        ) : (
          <div className="flex-1 flex flex-col justify-between">
            <div className="mb-2">
              <div className="flex justify-between items-center mb-1">
                <div className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">
                  Match Score
                </div>
                <div className={`text-xs ${textColor} font-medium`}>
                  {label}
                </div>
              </div>
              <div className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-gray-100">
                {percentage.toFixed(1)}%
              </div>
            </div>
            <div>
              <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5 mb-1">
                <div
                  className={`${color} h-2.5 rounded-full transition-all duration-500 ease-out`}
                  style={{ width: `${percentage}%` }}
                ></div>
              </div>
              <div className="flex justify-between items-center text-xs sm:text-sm text-gray-600 dark:text-gray-400">
                <span>Criteria Met: {conditionsMet}/{totalConditions}</span>
                <span className={textColor + " font-medium"}>
                  {percentage.toFixed(0)}%
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Add analysis descriptions
const analysisDescriptions = {
    'Warren Buffett': `Warren Buffett's Investment Criteria:
    • Return on Equity > 15%
    • Debt to Equity < 0.5
    • Current Ratio > 1.5
    • Consistent Earnings Growth > 10%
    • Net Profit Margin > 10%
    • P/E Ratio < 25
    • Operating Margin > Industry Average
    • Net Profit Margin > Industry Average
    • Sustainable Growth Rate > 15%

    Analysis based on company's financial strength, profitability, and growth metrics using TTM (Trailing Twelve Months) data.`,

    'William ONeil': `William O'Neil's CANSLIM Criteria:
    • Current Quarterly Earnings Growth > 25%
    • Price Strength (Price > 50-day Moving Average)
    • Industry Position (Positive Price Change)
    • Market Leadership (Market Cap > $1B, Volume > 500k)
    • Market Direction (Price > 200-day Moving Average)

    Focus on strong earnings growth, price momentum, and market leadership using current market data.`,

    'Benjamin Graham': `Benjamin Graham's Value Criteria:
    • Market Cap > $100M
    • Current Ratio > 2
    • Positive EPS for Past 5 Years
    • Current Dividend Yield > 0
    • Positive 5-Year Earnings Growth
    • P/E Ratio < 15
    • Price to Book < 1.5

    Conservative approach focusing on financial stability and value metrics using TTM data.`,

    'James OShaughnessy': `James O'Shaughnessy's Criteria:
    • Market Cap > $150M
    • Price to Sales Ratio < 1.5
    • Positive Operating Cash Flow
    • Trading Volume > 500k Daily
    • Positive Price Momentum (YTD)

    Focuses on market value, trading volume, and momentum factors using current market data.`,

    'Peter Lynch': `Peter Lynch's Investment Criteria:
    • Not in Finance Sector
    • P/E Ratio < 20
    • P/E vs Growth Rate (PEG Analysis)
    • Dividend-Adjusted PEG ≥ 0.5
    • Company Size & Stability (Market Cap > $2B)
    • Debt to Equity < 1.0
    • Consistent Earnings Growth

    Emphasizes company growth relative to valuation using both historical and current data.`
};

const CardFWarren = ({ symbol }) => {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const previousSymbol = useRef('');
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState({});

  const toggleInfo = (type) => {
    setShowInfo(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  };

  const performAnalysis = useCallback(async (ticker) => {
    // Skip if already loading or if the symbol is the same as the previous one
    if (!ticker || loading || ticker === previousSymbol.current) {
      console.log('Skipping analysis:', { ticker, loading, previousSymbol: previousSymbol.current });
      return;
    }
    
    setLoading(true);
    setError(null);
    previousSymbol.current = ticker;

    try {
      console.log('Making request to backend for ticker:', ticker);
      
      // Get the base URL from environment variable or use default
      const baseUrl = process.env.REACT_APP_API_URL || '/api';
      const response = await fetch(`${baseUrl}/analyze_all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include', // Important for CORS
        body: JSON.stringify({
          ticker: ticker,
          api_key: "oGNfp3JAE7yA27rrva4WyTSE3p2A7gKa"
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log('Received data from backend:', data);
      
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid response format from server');
      }

      // Transform the results to use full names as keys
      const transformedResults = {};
      Object.entries(data).forEach(([key, value]) => {
        const fullName = analysisTypes.find(type => analysisTypeKeys[type] === key);
        if (fullName) {
          transformedResults[fullName] = value;
        }
      });

      setResults(transformedResults);
    } catch (error) {
      console.error('Error fetching analysis:', error);
      setError(error.message);
      const errorResults = {};
      analysisTypes.forEach(type => {
        errorResults[type] = { 
          error: 'Failed to fetch data', 
          details: error.message,
          conditions_met: 0,
          total_conditions: 0
        };
      });
      setResults(errorResults);
    } finally {
      setLoading(false);
    }
  }, [loading]);

  React.useEffect(() => {
    if (symbol && symbol !== previousSymbol.current) {
      performAnalysis(symbol);
    }
  }, [symbol, performAnalysis]);

  if (!symbol) {
    return (
      <div className="bg-white dark:bg-dark-card w-full overflow-hidden rounded-lg shadow-lg">
        <div className="p-6 text-center text-gray-500 dark:text-gray-400">
          Enter a stock symbol to view analysis
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-dark-card w-full overflow-hidden rounded-lg shadow-lg">
      <section className="m-2 sm:m-4">
        <header className="border-b border-gray-200 dark:border-gray-700 p-3 sm:p-4">
          <h2 className="text-base sm:text-lg font-medium text-gray-900 dark:text-gray-100">
            {error ? (
              <span className="text-red-500">Error: {error}</span>
            ) : (
              `Guru Analysis for ${symbol}`
            )}
          </h2>
        </header>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3 sm:gap-4 p-2 sm:p-4">
          {analysisTypes.map((type) => (
            <div key={type} className="relative">
              <button
                onClick={() => toggleInfo(type)}
                className="absolute top-2 right-2 z-10 w-6 h-6 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100 focus:outline-none transition-colors duration-200"
                title={`Show ${type} analysis details`}
              >
                i
              </button>
              {showInfo[type] && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md max-h-[90vh] sm:max-h-[80vh] overflow-hidden">
                    <div className="sticky top-0 p-3 sm:p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center bg-white dark:bg-gray-800">
                      <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-gray-100 pr-8">
                        {type} Analysis Criteria
                      </h3>
                      <button
                        onClick={() => toggleInfo(type)}
                        className="absolute right-3 top-3 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 focus:outline-none transition-colors duration-200 text-xl p-1"
                      >
                        ×
                      </button>
                    </div>
                    <div className="p-3 sm:p-4 overflow-y-auto max-h-[calc(90vh-4rem)] sm:max-h-[calc(80vh-4rem)] text-gray-700 dark:text-gray-300">
                      <p className="text-sm sm:text-base whitespace-pre-line">
                        {analysisDescriptions[type]}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="h-full">
                <InfoCardWarren
                  title={type}
                  loading={loading}
                  conditionsMet={results[type]?.conditions_met || 0}
                  totalConditions={results[type]?.total_conditions || 0}
                  error={results[type]?.error}
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CardFWarren;
