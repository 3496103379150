import React from 'react';
import Search from './Search';
import ThemeIcon from './Background';
import { useMarket } from '../context/MarketContext';

const Header = ({ setSymbol }) => {
  const { marketStatus, currentTime, nextEventTime } = useMarket();

  return (
    <header className="bg-white dark:bg-gray-800 shadow-sm transition-colors duration-200">
      <div className="container mx-auto px-3 sm:px-4 lg:px-6 max-w-7xl">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between py-2 sm:py-3 gap-2 sm:gap-4">
          {/* Logo and Company Name */}
          <div className="flex items-center">
            <span className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent hover:from-blue-500 hover:to-blue-300 transition-all duration-300">
              Createfy
            </span>
          </div>

          {/* Search and Market Status - Desktop */}
          <div className="hidden sm:flex flex-1 items-center justify-end gap-6 max-w-3xl">
            {/* Search Bar - Made wider */}
            <div className="flex-1 min-w-[300px] max-w-2xl">
              <Search setSymbol={setSymbol} />
            </div>

            {/* Market Status and Time */}
            <div className="flex items-center gap-4 text-sm whitespace-nowrap">
              <div className="flex flex-col items-end gap-1">
                <div className="flex items-center gap-2">
                  <span className="text-gray-600 dark:text-gray-300 font-medium">
                    Market
                  </span>
                  <span className={`
                    inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${marketStatus.isOpen 
                      ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                      : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                    }
                  `}>
                    {marketStatus.isOpen ? 'Open' : 'Closed'}
                  </span>
                </div>
                <div className="text-xs text-gray-500 dark:text-gray-400">
                  {marketStatus.nextEvent} {nextEventTime}
                </div>
              </div>
              <div className="hidden lg:block text-right">
                <div className="text-gray-500 dark:text-gray-400">
                  {currentTime}
                </div>
              </div>
            </div>

            {/* Theme Toggle */}
            <div className="flex items-center">
              <ThemeIcon />
            </div>
          </div>

          {/* Mobile Layout */}
          <div className="sm:hidden w-full space-y-2">
            {/* Search Bar - Made wider for mobile */}
            <div className="flex-1 w-full">
              <Search setSymbol={setSymbol} />
            </div>

            {/* Market Status, Time, and Theme */}
            <div className="flex flex-col gap-2 pt-2 border-t border-gray-200 dark:border-gray-700">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-600 dark:text-gray-300 font-medium">
                    Market
                  </span>
                  <span className={`
                    inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium
                    ${marketStatus.isOpen 
                      ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                      : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                    }
                  `}>
                    {marketStatus.isOpen ? 'Open' : 'Closed'}
                  </span>
                </div>
                <ThemeIcon />
              </div>
              <div className="flex flex-col text-xs text-gray-500 dark:text-gray-400">
                <span>{marketStatus.nextEvent} {nextEventTime}</span>
                <span>{currentTime}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
