import React, { useState } from "react";
import { examplePeerData } from "../constants/example";

const PeerComparisonTable = ({ symbol }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [expandedRows, setExpandedRows] = useState(new Set());

  // Use example data if no symbol is provided
  const peerData = examplePeerData;

  const formatNumber = (num, type) => {
    if (type === 'price') return `$${Number(num).toFixed(2)}`;
    if (type === 'percentage') return `${Number(num).toFixed(2)}%`;
    if (type === 'marketCap') {
      if (num >= 1e12) return `$${(num / 1e12).toFixed(2)}T`;
      if (num >= 1e9) return `$${(num / 1e9).toFixed(2)}B`;
      if (num >= 1e6) return `$${(num / 1e6).toFixed(2)}M`;
      return `$${(num / 1e3).toFixed(2)}K`;
    }
    return num;
  };

  const toggleRow = (symbol) => {
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(symbol)) {
        newSet.delete(symbol);
      } else {
        newSet.add(symbol);
      }
      return newSet;
    });
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return peerData;

    return [...peerData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [peerData, sortConfig]);

  const columns = [
    { key: 'symbol', label: 'Symbol', format: 'text' },
    { key: 'price', label: 'Price', format: 'price' },
    { key: 'change', label: 'Change', format: 'percentage' },
    { key: 'marketCap', label: 'Market Cap', format: 'marketCap' },
    { key: 'peRatio', label: 'P/E Ratio', format: 'number' },
    { key: 'volume', label: 'Volume', format: 'marketCap' }
  ];

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100">
          Peer Comparison
        </h2>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Compare with similar companies
        </p>
      </div>

      {/* Desktop Table */}
      <div className="hidden sm:block overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                  onClick={() => requestSort(column.key)}
                >
                  <div className="flex items-center gap-2">
                    {column.label}
                    {sortConfig.key === column.key && (
                      <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {sortedData.map((peer) => (
              <tr 
                key={peer.symbol}
                className="hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200"
              >
                {columns.map((column) => (
                  <td 
                    key={column.key}
                    className={`px-6 py-4 whitespace-nowrap text-sm ${
                      column.key === 'symbol' 
                        ? 'font-medium text-gray-900 dark:text-gray-100'
                        : 'text-gray-500 dark:text-gray-400'
                    } ${
                      column.key === 'change'
                        ? Number(peer[column.key]) >= 0
                          ? 'text-green-600 dark:text-green-400'
                          : 'text-red-600 dark:text-red-400'
                        : ''
                    }`}
                  >
                    {formatNumber(peer[column.key], column.format)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile Cards */}
      <div className="sm:hidden space-y-4">
        {sortedData.map((peer) => (
          <div 
            key={peer.symbol}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden"
          >
            <button
              onClick={() => toggleRow(peer.symbol)}
              className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200"
            >
              <div className="flex items-center gap-3">
                <span className="font-medium text-gray-900 dark:text-gray-100">
                  {peer.symbol}
                </span>
                <span className={`text-sm font-medium px-2 py-0.5 rounded-full
                  ${Number(peer.change) >= 0
                    ? 'text-green-700 dark:text-green-400 bg-green-100 dark:bg-green-900/30'
                    : 'text-red-700 dark:text-red-400 bg-red-100 dark:bg-red-900/30'
                  }`}
                >
                  {formatNumber(peer.change, 'percentage')}
                </span>
              </div>
              <svg
                className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${
                  expandedRows.has(peer.symbol) ? 'rotate-180' : ''
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>

            {expandedRows.has(peer.symbol) && (
              <div className="px-4 pb-3 space-y-2">
                {columns.slice(1).map((column) => (
                  <div key={column.key} className="flex justify-between items-center">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {column.label}
                    </span>
                    <span className={`text-sm font-medium ${
                      column.key === 'change'
                        ? Number(peer[column.key]) >= 0
                          ? 'text-green-600 dark:text-green-400'
                          : 'text-red-600 dark:text-red-400'
                        : 'text-gray-900 dark:text-gray-100'
                    }`}>
                      {formatNumber(peer[column.key], column.format)}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PeerComparisonTable;