import dayjs from "dayjs"

export const exampleSearchResults = {
  count: 4,
  result: [
    {
      description: "NGM",
      displaySymbol: "NGM",
      symbol: "NGM",
      type: "Ricpraw stock"
    }
  ]
};

      export const candleStickChart = {
        chart: {
          height: '30%',
          type: 'candlestick',
        },
        annotations: {
          xaxis: [
            {
              x: 'Oct 06 14:00',
              borderColor: '#00E396',
              label: {
                borderColor: '#00E396',
                style: {
                  fontSize: '12px',
                  color: '#fff',
                  background: '#00E396',
                },
                orientation: 'horizontal',
                offsetY: 1,
                text: 'Annotation Test',
              },
            },
          ],
        },
        tooltip: {
          enabled: true,
          formatter: function(val) {
            return Math.round(val).toString();
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function(val) {
              return dayjs(val).format('YYYY MMM DD');
            },
          },
        },
        yaxis: {
          tooltip: {
            enabled: true,
            formatter: function(val) {
              return Math.round(val).toString();
            },
          },
        },
      };
      
      export const normalChart = {
        chart: {
          height: '30%',
          type: 'line',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function(val) {
              return dayjs(val).format('YYYY MMM DD');
            },
          },
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
          },
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
      };
      


export const exampleCompanyDetails = {
  // Basic Info
  name: "Apple Inc.",
  symbol: "AAPL",
  country: "US",
  isTrading: true,

  // Price & Market Data
  price: 185.92,
  change: 2.34,
  changePercent: 1.28,
  marketCap: 2890000000000,
  volume: 58900000,
  volumeChange: 5.23,
  avgVolume: 55700000,

  // Key Metrics
  peRatio: 30.5,
  eps: 6.13,
  beta: 1.28,
  yearHigh: 198.23,
  yearLow: 124.17,
  priceToBook: 44.31,
  dividendYield: 0.51,

  // Performance Changes
  dayChange: 1.24,
  weekChange: 2.45,
  monthChange: 5.67,
  threeMonthChange: 8.90,
  sixMonthChange: 15.67,
  ytdChange: 42.31,
  yearChange: 48.76,

  // Company Information
  description: "Apple Inc. designs, manufactures, and markets smartphones, personal computers, tablets, wearables, and accessories worldwide. The company offers iPhone, Mac, iPad, AirPods, Apple TV, Apple Watch, and related services and accessories. It also provides AppleCare, iCloud, Apple Card, and other digital services.",
  sector: "Technology",
  industry: "Consumer Electronics",
  exchange: "NASDAQ",
  currency: "USD",
  
  // Additional Metrics
  employees: 164000,
  sharesOutstanding: 15520000000,
  float: 15400000000,
  nextEarningsDate: "2024-01-25",
  
  // Financial Health
  currentRatio: 1.02,
  debtToEquity: 261.45,
  quickRatio: 0.97,
  returnOnEquity: 160.09,
  returnOnAssets: 28.31,
  operatingMargin: 30.11,
  profitMargin: 25.31
};

      export const exampleGetStatistics =
        {
          "quoteType": {
            "exchange": "NGM",
            
          },
          
        }

      export const examplePeerData = [
        {
          symbol: "AAPL",
          price: 185.92,
          change: 2.34,
          marketCap: 2890000000000,
          peRatio: 30.5,
          volume: 58900000
        },
        {
          symbol: "MSFT",
          price: 374.58,
          change: 1.89,
          marketCap: 2780000000000,
          peRatio: 35.8,
          volume: 23400000
        },
        {
          symbol: "GOOGL",
          price: 133.32,
          change: -0.45,
          marketCap: 1680000000000,
          peRatio: 25.6,
          volume: 28700000
        },
        {
          symbol: "META",
          price: 334.92,
          change: 3.21,
          marketCap: 860000000000,
          peRatio: 29.4,
          volume: 19500000
        },
        {
          symbol: "NVDA",
          price: 466.27,
          change: 4.56,
          marketCap: 1150000000000,
          peRatio: 62.3,
          volume: 42600000
        }
      ];

