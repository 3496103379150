import React from 'react';
import { useMarket } from '../context/MarketContext';

const MarketMovers = ({ onSelectStock }) => {
  const { marketMovers, marketMoversLoading, marketMoversError } = useMarket();
  
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(price);
  };

  const renderStockList = (stocks) => {
    if (marketMoversError) {
      return (
        <div className="text-red-500 dark:text-red-400 text-sm py-2">
          Error loading market data. Please try again later.
        </div>
      );
    }

    if (marketMoversLoading || !stocks || stocks.length === 0) {
      return (
        <div className="animate-pulse space-y-3">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="flex justify-between items-center py-2">
              <div className="flex items-center space-x-2">
                <div className="h-4 w-16 bg-gray-200 dark:bg-gray-700 rounded"></div>
                <div className="h-4 w-24 bg-gray-200 dark:bg-gray-700 rounded"></div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="h-4 w-16 bg-gray-200 dark:bg-gray-700 rounded"></div>
                <div className="h-4 w-16 bg-gray-200 dark:bg-gray-700 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return stocks.map((stock) => (
      <div 
        key={stock.symbol} 
        className="flex justify-between items-center py-2 border-b border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer transition-colors duration-200"
        onClick={() => onSelectStock(stock.symbol)}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onSelectStock(stock.symbol);
          }
        }}
      >
        <div className="flex items-center">
          <span className="font-medium text-gray-900 dark:text-gray-100">{stock.symbol}</span>
          <span className="ml-2 text-sm text-gray-500 dark:text-gray-400 hidden sm:inline">
            {stock.name?.length > 20 ? `${stock.name.substring(0, 20)}...` : stock.name}
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <span className="font-medium text-gray-900 dark:text-gray-100">
            {formatPrice(stock.price)}
          </span>
          <span className={`${
            stock.changesPercentage >= 0 
              ? 'text-green-600 dark:text-green-500' 
              : 'text-red-600 dark:text-red-500'
          } font-medium`}>
            {stock.changesPercentage >= 0 ? '+' : ''}
            {stock.changesPercentage.toFixed(2)}%
          </span>
        </div>
      </div>
    ));
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {/* Top Gainers */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4 transition-colors duration-200">
        <h3 className="text-lg font-semibold mb-4 text-green-600 dark:text-green-500 flex items-center">
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
          </svg>
          Top Gainers
        </h3>
        <div className="space-y-2">
          {renderStockList(marketMovers?.gainers?.slice(0, 5))}
        </div>
      </div>

      {/* Top Losers */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4 transition-colors duration-200">
        <h3 className="text-lg font-semibold mb-4 text-red-600 dark:text-red-500 flex items-center">
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 17h8m0 0v-8m0 8l-8-8-4 4-6-6" />
          </svg>
          Top Losers
        </h3>
        <div className="space-y-2">
          {renderStockList(marketMovers?.losers?.slice(0, 5))}
        </div>
      </div>

      {/* Most Active */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4 transition-colors duration-200">
        <h3 className="text-lg font-semibold mb-4 text-blue-600 dark:text-blue-500 flex items-center">
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6M13 17h8m0 0v-8m0 8l-8-8-4 4-6-6" />
          </svg>
          Most Active
        </h3>
        <div className="space-y-2">
          {renderStockList(marketMovers?.active?.slice(0, 5))}
        </div>
      </div>
    </div>
  );
};

export default MarketMovers;
