import React, { createContext, useContext, useState, useEffect } from 'react';
import config from '../config';

const MarketContext = createContext();

export const MarketProvider = ({ children }) => {
  const [marketStatus, setMarketStatus] = useState({ 
    isOpen: false,
    nextEvent: '',
    nextEventTime: null
  });
  const [currentTime, setCurrentTime] = useState('');
  const [marketMovers, setMarketMovers] = useState({
    gainers: [],
    losers: [],
    active: []
  });
  const [marketMoversLoading, setMarketMoversLoading] = useState(true);
  const [marketMoversError, setMarketMoversError] = useState(null);

  // Fetch market movers data
  const fetchMarketMovers = async () => {
    setMarketMoversLoading(true);
    setMarketMoversError(null);

    try {
      const apiKey = config.fmpApiKey;
      if (!apiKey) {
        throw new Error('API key is not configured');
      }

      console.log('Fetching market movers data...'); // Debug log
      const baseUrl = 'https://financialmodelingprep.com/api/v3';
      
      // Fetch gainers, losers, and most active stocks in parallel
      const [gainersRes, losersRes, activeRes] = await Promise.all([
        fetch(`${baseUrl}/stock_market/gainers?apikey=${apiKey}`),
        fetch(`${baseUrl}/stock_market/losers?apikey=${apiKey}`),
        fetch(`${baseUrl}/stock_market/actives?apikey=${apiKey}`)
      ]);

      // Check if any response failed
      if (!gainersRes.ok || !losersRes.ok || !activeRes.ok) {
        console.error('API Response not OK:', {
          gainers: gainersRes.status,
          losers: losersRes.status,
          active: activeRes.status
        });
        throw new Error('Failed to fetch market data');
      }

      const [gainers, losers, active] = await Promise.all([
        gainersRes.json(),
        losersRes.json(),
        activeRes.json()
      ]);

      console.log('Received market data:', { // Debug log
        gainersCount: gainers?.length,
        losersCount: losers?.length,
        activeCount: active?.length
      });

      // Validate response data
      if (!Array.isArray(gainers) || !Array.isArray(losers) || !Array.isArray(active)) {
        throw new Error('Invalid data format received from API');
      }

      setMarketMovers({
        gainers: gainers || [],
        losers: losers || [],
        active: active || []
      });
      setMarketMoversError(null);
    } catch (error) {
      console.error('Error fetching market movers:', error);
      setMarketMoversError(error.message);
      // Keep the previous state on error
      setMarketMovers(prev => ({
        gainers: prev.gainers,
        losers: prev.losers,
        active: prev.active
      }));
    } finally {
      setMarketMoversLoading(false);
    }
  };

  useEffect(() => {
    const getMarketTimes = (date) => {
      // Create dates for market open (9:30 AM EST) and close (4:00 PM EST)
      const marketOpen = new Date(date);
      marketOpen.setHours(9, 30, 0);
      
      const marketClose = new Date(date);
      marketClose.setHours(16, 0, 0);

      return { marketOpen, marketClose };
    };

    const getNextMarketEvent = (now, isOpen) => {
      const today = new Date(now);
      const { marketOpen, marketClose } = getMarketTimes(today);
      
      // If market is closed, calculate next opening time
      if (!isOpen) {
        if (now > marketClose) {
          // After today's close, next open is tomorrow or next business day
          let nextDay = new Date(today);
          nextDay.setDate(nextDay.getDate() + 1);
          
          // Skip to Monday if it's Friday after close
          if (today.getDay() === 5) {
            nextDay.setDate(nextDay.getDate() + 2);
          }
          
          const nextOpen = getMarketTimes(nextDay).marketOpen;
          return { event: 'Opens', time: nextOpen };
        } else {
          // Before today's open
          return { event: 'Opens', time: marketOpen };
        }
      } else {
        // Market is open, next event is close
        return { event: 'Closes', time: marketClose };
      }
    };

    const updateMarketStatus = () => {
      const now = new Date();
      
      // Get EST time
      const estOptions = { timeZone: 'America/New_York' };
      const estTime = new Date(now.toLocaleString('en-US', estOptions));
      
      const day = estTime.getDay();
      const hour = estTime.getHours();
      const minute = estTime.getMinutes();
      
      // Check if market is open (9:30 AM - 4:00 PM EST, Monday-Friday)
      const isOpen = day >= 1 && day <= 5 && // Monday to Friday
                    ((hour === 9 && minute >= 30) || // After 9:30 AM
                     (hour > 9 && hour < 16) ||      // 10 AM to 3:59 PM
                     (hour === 16 && minute === 0));  // Until 4:00 PM

      // Get next market event
      const nextEvent = getNextMarketEvent(estTime, isOpen);

      setMarketStatus({ 
        isOpen,
        nextEvent: nextEvent.event,
        nextEventTime: nextEvent.time
      });
    };

    const updateTime = () => {
      const now = new Date();
      
      // Format current time in local timezone
      const localTime = now.toLocaleString('en-US', {
        weekday: 'short',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      // Format EST time
      const estTime = now.toLocaleString('en-US', {
        timeZone: 'America/New_York',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      });

      setCurrentTime(`${localTime} (${estTime})`);
      updateMarketStatus();
    };

    // Initial updates
    updateTime();
    updateMarketStatus();
    fetchMarketMovers(); // Initial fetch of market movers

    // Set intervals for updates
    const timeInterval = setInterval(updateTime, 1000);
    const marketInterval = setInterval(updateMarketStatus, 30000); // Check every 30 seconds
    const moversInterval = setInterval(fetchMarketMovers, 60000); // Update market movers every minute

    return () => {
      clearInterval(timeInterval);
      clearInterval(marketInterval);
      clearInterval(moversInterval);
    };
  }, []);

  const formatNextEventTime = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/New_York',
      timeZoneName: 'short'
    });
  };

  return (
    <MarketContext.Provider value={{ 
      marketStatus, 
      currentTime,
      nextEventTime: formatNextEventTime(marketStatus.nextEventTime),
      marketMovers,
      marketMoversLoading,
      marketMoversError
    }}>
      {children}
    </MarketContext.Provider>
  );
};

export const useMarket = () => {
  const context = useContext(MarketContext);
  if (!context) {
    throw new Error('useMarket must be used within a MarketProvider');
  }
  return context;
};
