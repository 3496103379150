import React, { useState } from "react";
import Header from "./Header";
import Overview from "./Overview";
import CardFWarren from "./warren_peter";
import ChartSwitcher from "./chartswitch";
import CompanyOutlookTable from "./livechartdescription";
import Description from "./Description";
import PeerComparisonTable from "./PeerComparisonTable";
import MarketMovers from "./MarketMovers";
import { MarketProvider } from "../context/MarketContext";

const Dashboard = () => {
  const [symbol, setSymbol] = useState("");

  return (
    <MarketProvider>
      <div className="min-h-screen bg-gray-50 dark:bg-dark-bg transition-colors duration-200">
        {/* Header Section */}
        <div className="sticky top-0 z-50 w-full">
          <Header setSymbol={setSymbol} />
        </div>

        {/* Main Content */}
        <main className="container mx-auto px-3 sm:px-4 lg:px-6 py-4 sm:py-6 space-y-6 font-afacad max-w-7xl">
          {/* Title and Description Section */}
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 dark:text-gray-100 transition-colors duration-200">
                {symbol || "Createfy"}
              </h1>
              <div className="w-full sm:w-auto">
                <Description symbol={symbol} />
              </div>
            </div>
          </div>

          {/* Market Movers Section - Show at top when no symbol */}
          {!symbol && (
            <div className="space-y-4">
              <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 dark:text-gray-100">
                Market Movers
              </h2>
              <MarketMovers onSelectStock={setSymbol} />
            </div>
          )}

          {/* Analysis Section - Guru Cards */}
          {symbol && (
            <div className="space-y-4 sm:space-y-6 bg-white dark:bg-gray-800 rounded-lg shadow-sm transition-all duration-200">
              <CardFWarren symbol={symbol} />
            </div>
          )}

          {/* Chart Section */}
          {symbol && (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-2 sm:p-4 transition-all duration-200">
              <ChartSwitcher symbol={symbol} />
            </div>
          )}

          {/* Overview Section */}
          {symbol && (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-2 sm:p-4 transition-all duration-200">
              <Overview symbol={symbol} />
            </div>
          )}

          {/* Market Movers Section - Show after Overview when symbol is entered */}
          {symbol && (
            <div className="space-y-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4 transition-all duration-200">
              <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 dark:text-gray-100">
                Market Performance Overview
              </h2>
              <MarketMovers onSelectStock={setSymbol} />
            </div>
          )}

          {/* Peer Comparison Section */}
          {symbol && (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-2 sm:p-4 transition-all duration-200 overflow-x-auto">
              <PeerComparisonTable symbol={symbol} />
            </div>
          )}
        </main>

        {/* Footer */}
        <footer className="mt-8 py-4 px-4 text-center text-sm text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 transition-colors duration-200">
          <div className="container mx-auto max-w-7xl">
            <p>© 2024 Createfy. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </MarketProvider>
  );
};

export default Dashboard;
