import React, { useState, useEffect, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { fetchCustomStockData } from './services';
import { formatCustomStockData } from './Utils2';

const Livechart = ({ symbol, dateRange }) => {
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains('dark'));

  // Listen for dark mode changes
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          setIsDarkMode(document.documentElement.classList.contains('dark'));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const fetchStockData = async () => {
      if (!symbol) return;
      
      setLoading(true);
      setError(null);
      try {
        console.log(`[Candlestick] Fetching data for ${symbol} with range ${dateRange}`);
        const data = await fetchCustomStockData(symbol, dateRange);
        console.log('[Candlestick] Raw data:', data);
        if (!data || data.length === 0) {
          throw new Error('No data available');
        }
        setStockData(data);
      } catch (error) {
        console.error('[Candlestick] Error:', error);
        setError(error.message || 'Failed to load chart data');
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
  }, [symbol, dateRange]);

  const candleData = useMemo(() => {
    if (!stockData.length) return [];
    console.log('[Candlestick] Formatting data:', stockData);
    const formatted = formatCustomStockData(stockData);
    console.log('[Candlestick] Formatted data:', formatted);
    return formatted;
  }, [stockData]);

  const optionsCandle = {
    chart: {
      type: 'candlestick',
      height: 350,
      background: 'transparent',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        },
        autoSelected: 'zoom',
        position: 'top',
        offsetY: 20,
        offsetX: 0,
        export: {
          csv: {
            filename: `${symbol}-stock-data`
          },
          svg: {
            filename: `${symbol}-chart`
          }
        }
      },
      foreColor: isDarkMode ? '#F3F4F6' : '#111827',
      padding: {
        top: 40,
        right: 15,
        bottom: 15,
        left: 15
      },
      zoom: {
        enabled: true,
        type: 'xy',
        autoScaleYaxis: true
      }
    },
    theme: {
      mode: isDarkMode ? 'dark' : 'light'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        formatter: function(val) {
          return new Date(val).toLocaleDateString();
        },
        style: {
          colors: isDarkMode ? '#F3F4F6' : '#111827'
        }
      },
      axisBorder: {
        color: isDarkMode ? '#374151' : '#E5E7EB'
      },
      axisTicks: {
        color: isDarkMode ? '#374151' : '#E5E7EB'
      }
    },
    yaxis: {
      tooltip: {
        enabled: true
      },
      labels: {
        formatter: (value) => `$${value.toFixed(2)}`,
        style: {
          colors: isDarkMode ? '#F3F4F6' : '#111827'
        }
      }
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: '#26C281',
          downward: '#EF4444'
        },
        wick: {
          useFillColor: true
        }
      }
    },
    title: {
      text: symbol ? `${symbol} Stock Price` : 'Enter a symbol',
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: isDarkMode ? '#F3F4F6' : '#111827'
      }
    },
    grid: {
      borderColor: isDarkMode ? '#374151' : '#f1f1f1',
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    tooltip: {
      enabled: true,
      theme: isDarkMode ? 'dark' : 'light',
      x: {
        format: 'MMM dd yyyy'
      },
      y: {
        formatter: (value) => `$${value.toFixed(2)}`
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          height: 400,
          toolbar: {
            offsetY: 25,
            offsetX: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            }
          },
          padding: {
            top: 50,
            right: 10,
            bottom: 10,
            left: 10
          }
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '10px'
            },
            maxWidth: 60
          }
        },
        xaxis: {
          labels: {
            style: {
              fontSize: '10px'
            },
            maxHeight: 50
          }
        },
        grid: {
          padding: {
            top: 20,
            right: 10,
            bottom: 10,
            left: 10
          }
        }
      }
    }],
    tooltip: {
      enabled: true,
      theme: isDarkMode ? 'dark' : 'light',
      x: {
        format: 'MMM dd yyyy'
      },
      y: {
        formatter: (value) => `$${value.toFixed(2)}`
      },
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetY: 60,
        offsetX: 0
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[350px] bg-white dark:bg-gray-800">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600 dark:border-purple-400"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-[350px] bg-white dark:bg-gray-800">
        <div className="text-center">
          <p className="font-medium text-red-500 dark:text-red-400">{error}</p>
          <p className="text-sm mt-2 text-gray-500 dark:text-gray-400">Please try again later</p>
        </div>
      </div>
    );
  }

  if (!symbol) {
    return (
      <div className="flex items-center justify-center h-[350px] bg-white dark:bg-gray-800">
        <span className="text-gray-500 dark:text-gray-400">Enter a stock symbol to view chart</span>
      </div>
    );
  }

  if (!candleData.length) {
    return (
      <div className="flex items-center justify-center h-[350px] bg-white dark:bg-gray-800">
        <span className="text-gray-500 dark:text-gray-400">No data available for this time range</span>
      </div>
    );
  }

  return (
    <div className="chart-container bg-white dark:bg-gray-800 rounded-lg overflow-hidden p-4 mt-6 mb-4">
      <div className="h-[400px] relative">
        <div className="absolute top-0 left-0 right-0 h-12 bg-transparent" />
        <ReactApexChart 
          options={optionsCandle} 
          series={[{ data: candleData }]} 
          type="candlestick" 
          height="100%"
        />
      </div>
    </div>
  );
};

export default Livechart;