import React from "react";

const SearchResults = ({ results, onSelect, searchTerm }) => {
  // Highlight matching text in search results
  const highlightMatch = (text, term) => {
    if (!term) return text;
    
    const parts = text.split(new RegExp(`(${term})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => 
          part.toLowerCase() === term.toLowerCase() ? (
            <span key={i} className="bg-yellow-200 dark:bg-yellow-900 text-gray-900 dark:text-yellow-100 rounded px-0.5">
              {part}
            </span>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
      </span>
    );
  };

  return (
    <ul className="py-2 divide-y divide-gray-200 dark:divide-gray-700">
      {results.map((item) => (
        <li key={item.symbol}>
          <button
            onClick={() => onSelect(item.symbol)}
            className="w-full px-4 py-3 sm:py-2.5 flex flex-col sm:flex-row sm:items-center justify-between hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200 group"
          >
            {/* Symbol and Name */}
            <div className="flex-1 min-w-0">
              <div className="flex items-center gap-2">
                <span className="text-base sm:text-sm font-semibold text-gray-900 dark:text-gray-100 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">
                  {highlightMatch(item.symbol, searchTerm)}
                </span>
                <span className="px-2 py-0.5 text-xs rounded-full bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300">
                  {item.exchangeShortName || item.exchange}
                </span>
              </div>
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400 truncate">
                {highlightMatch(item.name, searchTerm)}
              </p>
            </div>

            {/* Additional Info */}
            <div className="mt-2 sm:mt-0 flex flex-row sm:flex-col items-center sm:items-end gap-3 sm:gap-1 text-right">
              {item.price && (
                <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                  ${Number(item.price).toFixed(2)}
                </span>
              )}
              {item.changesPercentage && (
                <span className={`text-xs font-medium px-2 py-0.5 rounded
                  ${Number(item.changesPercentage) >= 0 
                    ? 'text-green-700 dark:text-green-400 bg-green-100 dark:bg-green-900/30'
                    : 'text-red-700 dark:text-red-400 bg-red-100 dark:bg-red-900/30'
                  }`}
                >
                  {Number(item.changesPercentage) >= 0 ? '+' : ''}
                  {Number(item.changesPercentage).toFixed(2)}%
                </span>
              )}
            </div>
          </button>
        </li>
      ))}

      {/* Show "View More" button if there are many results */}
      {results.length > 10 && (
        <li className="px-4 py-3 text-center">
          <button
            className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 font-medium transition-colors duration-200"
            onClick={() => {/* Implement view more functionality */}}
          >
            View More Results
          </button>
        </li>
      )}

      {/* Empty State */}
      {results.length === 0 && (
        <li className="px-4 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
          No results found. Try a different search term.
        </li>
      )}
    </ul>
  );
};

export default SearchResults;
