import React, { useState, useEffect, useMemo } from "react";
import { useMarket } from '../context/MarketContext';


// Helper formatting functions
const formatNumber = (num) => {
  if (num === null || num === undefined) return 'N/A';
  
  // Format large numbers (volume, market cap)
  if (Math.abs(num) >= 1e6) {
    if (Math.abs(num) >= 1e12) return `${(num / 1e12).toFixed(2)}T`;
    if (Math.abs(num) >= 1e9) return `${(num / 1e9).toFixed(2)}B`;
    if (Math.abs(num) >= 1e6) return `${(num / 1e6).toFixed(2)}M`;
  }
  
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num);
};

const formatLargeNumber = (num) => {
  if (!num) return 'N/A';
  if (num >= 1e12) return `$${(num / 1e12).toFixed(2)}T`;
  if (num >= 1e9) return `$${(num / 1e9).toFixed(2)}B`;
  if (num >= 1e6) return `$${(num / 1e6).toFixed(2)}M`;
  return `$${num.toLocaleString()}`;
};

const formatPercentage = (num) => {
  if (!num && num !== 0) return 'N/A';
  const value = Number(num);
  return `${value > 0 ? '+' : ''}${value.toFixed(2)}%`;
};

const MetricCard = ({ title, value, change, subValue, isPrice, isVolume, isMarketCap, isBidAsk, isRange, isDividend }) => (
  <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-2.5 hover:bg-gray-100 dark:hover:bg-gray-700/50 transition-colors duration-200">
    <span className="text-xs font-medium text-gray-500 dark:text-gray-400">
      {title}
    </span>
    <div className="flex items-baseline gap-1.5 mt-0.5">
      <span className="text-sm font-semibold text-gray-900 dark:text-gray-100">
        {isPrice && !Array.isArray(value) ? `$${formatNumber(value)}` :
         isVolume ? new Intl.NumberFormat('en-US').format(value) :
         isMarketCap ? formatLargeNumber(value) :
         isBidAsk ? `$${value[0]} × ${value[1]}` :
         isRange ? `$${value[0]} - $${value[1]}` :
         isDividend ? `$${value[0]} (${value[1]})` :
         value}
      </span>
      {change && (
        <span className={`text-xs font-medium px-1 py-0.5 rounded-sm
          ${change >= 0 
            ? 'text-green-700 bg-green-50 dark:text-green-400 dark:bg-green-900/30'
            : 'text-red-700 bg-red-50 dark:text-red-400 dark:bg-red-900/30'
          }`}>
          {change >= 0 ? '↑' : '↓'} {formatPercentage(Math.abs(change))}
        </span>
      )}
    </div>
    {subValue && (
      <span className="text-xs text-gray-400 dark:text-gray-500 mt-0.5 block">
        {subValue}
      </span>
    )}
  </div>
);

const Overview = ({ symbol }) => {
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newsData, setNewsData] = useState([]); // Add news state
  const { marketStatus } = useMarket();

  // Add deduplication of news using useMemo
  const uniqueNewsData = useMemo(() => {
    const seen = new Set();
    return newsData.filter(news => {
      const duplicate = seen.has(news.title);
      seen.add(news.title);
      return !duplicate;
    });
  }, [newsData]);

  const API_KEY = "oGNfp3JAE7yA27rrva4WyTSE3p2A7gKa";
  const BASE_URL = "https://financialmodelingprep.com/api/v3";

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (!symbol) return;
      
      setLoading(true);
      setError(null);

      try {
        // Calculate dates for historical data
        const today = new Date();
        const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 1));
        const formattedOneYearAgo = oneYearAgo.toISOString().split('T')[0];
        const formattedToday = new Date().toISOString().split('T')[0];

        // Fetch all data in parallel
        const [
          quoteResponse, 
          profileResponse, 
          metricsResponse, 
          historicalResponse,
          ratiosResponse,
          earningsResponse,
          keyMetricsResponse,
          newsResponse // Add news API call
        ] = await Promise.all([
          fetch(`${BASE_URL}/quote/${symbol}?apikey=${API_KEY}`),
          fetch(`${BASE_URL}/profile/${symbol}?apikey=${API_KEY}`),
          fetch(`${BASE_URL}/key-metrics-ttm/${symbol}?apikey=${API_KEY}`),
          fetch(`${BASE_URL}/historical-price-full/${symbol}?from=${formattedOneYearAgo}&apikey=${API_KEY}`),
          fetch(`${BASE_URL}/ratios-ttm/${symbol}?apikey=${API_KEY}`),
          fetch(`${BASE_URL}/earnings-calendar/${symbol}?apikey=${API_KEY}`),
          fetch(`${BASE_URL}/key-metrics/${symbol}?apikey=${API_KEY}&limit=1`),
          fetch(`${BASE_URL}/stock_news?tickers=${symbol}&limit=5&apikey=${API_KEY}`) // Fetch latest 5 news items
        ]);

        const [
          quoteData, 
          profileData, 
          metricsData, 
          historicalData,
          ratiosData,
          earningsData,
          keyMetricsData,
          newsData // Add news data
        ] = await Promise.all([
          quoteResponse.json(),
          profileResponse.json(),
          metricsResponse.json(),
          historicalResponse.json(),
          ratiosResponse.json(),
          earningsResponse.json(),
          keyMetricsResponse.json(),
          newsResponse.json()
        ]);

        // Set news data
        setNewsData(newsData || []);

        // Calculate price changes for different periods
        const historical = historicalData.historical || [];
        const priceChanges = calculatePriceChanges(historical);

        // Combine all data
        const combinedData = {
          ...quoteData[0],
          ...profileData[0],
          ...(metricsData[0] || {}),
          ...(ratiosData[0] || {}),
          ...(keyMetricsData[0] || {}),
          ...priceChanges,
          nextEarningsDate: earningsData[0]?.date
        };

        setCompanyData(combinedData);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [symbol]);

  useEffect(() => {
    const fetchHistoricalData = async () => {
      if (!symbol) return;
      
      try {
        const maxHistoricalResponse = await fetch(
          `${BASE_URL}/historical-price-full/${symbol}?apikey=${API_KEY}&serietype=line`
        );
        const maxData = await maxHistoricalResponse.json();
        
        if (maxData.historical && maxData.historical.length > 0) {
          const oldestPrice = maxData.historical[maxData.historical.length - 1].price;
          const currentPrice = maxData.historical[0].price;
          const maxChange = ((currentPrice - oldestPrice) / oldestPrice) * 100;
          
          setCompanyData(prev => ({
            ...prev,
            maxChange
          }));
        }
      } catch (error) {
        console.error('Error fetching historical data:', error);
      }
    };

    fetchHistoricalData();
  }, [symbol]);

  const calculatePriceChanges = (historical) => {
    if (!historical?.length) return {};

    const getPrice = (daysAgo) => {
      try {
        const targetDate = new Date();
        targetDate.setDate(targetDate.getDate() - daysAgo);
        
        // Find the closest date that's not after our target date
        const priceData = historical.find(item => {
          const itemDate = new Date(item.date);
          return itemDate <= targetDate;
        });
        
        return priceData?.close;
      } catch (error) {
        console.error(`Error getting price for ${daysAgo} days ago:`, error);
        return null;
      }
    };

    const calculateChange = (oldPrice, currentPrice) => {
      if (!oldPrice || !currentPrice) return null;
      const change = ((currentPrice - oldPrice) / oldPrice) * 100;
      // Round to 2 decimal places
      return Math.round(change * 100) / 100;
    };

    const currentPrice = historical[0]?.close;
    const oldestPrice = historical[historical.length - 1]?.close;

    return {
      '1D': calculateChange(getPrice(1), currentPrice),
      '5D': calculateChange(getPrice(5), currentPrice),
      '1M': calculateChange(getPrice(30), currentPrice),
      '6M': calculateChange(getPrice(180), currentPrice),
      '1Y': calculateChange(getPrice(365), currentPrice),
      'MAX': calculateChange(oldestPrice, currentPrice)
    };
  };



  // Add date formatting function
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    } catch (e) {
      return 'N/A';
    }
  };




  const MetricSection = ({ title, children }) => (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
      <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100 mb-4">
        {title}
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
        {children}
      </div>
    </div>
  );

  const PricePerformanceCard = ({ label, value, isLoading }) => {
    const isPositive = (value || 0) >= 0;
    
    if (isLoading) {
      return (
        <div className="flex flex-col items-center p-3 rounded-lg bg-gray-50 dark:bg-gray-800/50 animate-pulse">
          <div className="h-4 w-8 bg-gray-200 dark:bg-gray-700 rounded mb-2"></div>
          <div className="h-5 w-16 bg-gray-200 dark:bg-gray-700 rounded"></div>
        </div>
      );
    }

    return (
      <div className={`
        flex flex-col items-center p-3 rounded-lg
        ${isPositive 
          ? 'bg-gradient-to-br from-green-50 to-green-100/50 dark:from-green-900/20 dark:to-green-800/10'
          : 'bg-gradient-to-br from-red-50 to-red-100/50 dark:from-red-900/20 dark:to-red-800/10'
        }
        hover:shadow-lg hover:scale-105 transform transition-all duration-200 ease-in-out
        cursor-default
      `}>
        <div className="text-xs font-medium text-gray-600 dark:text-gray-400 mb-1">
          {label}
        </div>
        <div className={`
          text-sm font-bold flex items-center gap-1
          ${isPositive 
            ? 'text-green-600 dark:text-green-400' 
            : 'text-red-600 dark:text-red-400'
          }
        `}>
          {value !== null ? (
            <>
              <span className="text-lg">
                {isPositive ? '↑' : '↓'}
              </span>
              <span>
                {isPositive ? '+' : ''}
                {formatPercentage(value)}
              </span>
            </>
          ) : (
            'N/A'
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="animate-pulse space-y-6">
        <div className="flex justify-between items-center">
          <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/4"></div>
          <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-20"></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {[...Array(6)].map((_, i) => (
            <div key={i} className="h-24 bg-gray-200 dark:bg-gray-700 rounded"></div>
          ))}
        </div>
        <div className="h-40 bg-gray-200 dark:bg-gray-700 rounded"></div>
        <div className="h-60 bg-gray-200 dark:bg-gray-700 rounded"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 dark:text-red-400 p-4">
        Error loading company data: {error}
      </div>
    );
  }

  if (!companyData) {
    return (
      <div className="text-center text-gray-500 dark:text-gray-400 p-4">
        No data available
      </div>
    );
  }

  const NewsCard = ({ news }) => {
    const formatNewsDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    };

    // Simple sentiment analysis based on content
    const analyzeSentiment = () => {
      const positiveWords = [
        // Market performance
        'buy', 'bullish', 'upside', 'gains', 'rally', 'surge', 'jump', 'climb',
        // Growth and strength
        'growth', 'growing', 'strong', 'stronger', 'strength', 'boost', 'boosted',
        // Leadership and success
        'lead', 'leader', 'leading', 'outperform', 'beat', 'beats', 'exceeded',
        // Ratings and recommendations
        'upgrade', 'upgraded', 'buy', 'overweight', 'positive',
        // Qualitative terms
        'best', 'top', 'good', 'better', 'astounding', 'excellent', 'favorite',
        'opportunity', 'opportunities', 'promising', 'successful', 'success',
        // Innovation and progress
        'innovation', 'innovative', 'advance', 'advancing', 'progress',
        // Financial health
        'profitable', 'profit', 'profits', 'earnings', 'revenue'
      ];

      const negativeWords = [
        // Market performance
        'sell', 'bearish', 'downside', 'losses', 'slump', 'plunge', 'drop', 'fell',
        // Weakness and decline
        'weak', 'weaker', 'weakness', 'decline', 'declining', 'decreased',
        // Underperformance
        'underperform', 'lag', 'lagging', 'miss', 'missed', 'disappointing',
        // Ratings and recommendations
        'downgrade', 'downgraded', 'sell', 'underweight', 'negative',
        // Risk and concerns
        'risk', 'risks', 'risky', 'warning', 'caution', 'cautious',
        'concern', 'concerns', 'concerning', 'worried', 'worry',
        // Financial problems
        'loss', 'losses', 'debt', 'liability', 'bankruptcy',
        // Market conditions
        'volatile', 'volatility', 'uncertainty', 'pressure', 'pressured',
        'crash', 'crisis', 'problem', 'problems', 'trouble'
      ];
      
      const content = (news.title + ' ' + news.text).toLowerCase();
      
      let positiveCount = positiveWords.filter(word => content.includes(word)).length;
      let negativeCount = negativeWords.filter(word => content.includes(word)).length;

      // If explicit sentiment is provided, use it
      if (news.sentiment === 'Positive' || news.sentiment === 'Negative') {
        return news.sentiment;
      }
      
      // Otherwise determine based on content
      if (positiveCount > negativeCount) {
        return 'Positive';
      } else if (negativeCount > positiveCount) {
        return 'Negative';
      } else {
        // If counts are equal, look for specific strong indicators
        if (content.includes('buy') || content.includes('bullish') || content.includes('upgrade')) {
          return 'Positive';
        } else if (content.includes('sell') || content.includes('bearish') || content.includes('downgrade')) {
          return 'Negative';
        }
        return 'Neutral';
      }
    };

    const sentiment = analyzeSentiment();
    const isPositive = sentiment === 'Positive';
    const isNeutral = sentiment === 'Neutral';

    const getSentimentStyles = () => {
      if (isPositive) {
        return 'border-green-200 dark:border-green-800 bg-green-50 dark:bg-green-900/10 hover:bg-green-100/50 dark:hover:bg-green-800/20';
      } else if (isNeutral) {
        return 'border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900/10 hover:bg-gray-100/50 dark:hover:bg-gray-800/20';
      } else {
        return 'border-red-200 dark:border-red-800 bg-red-50 dark:bg-red-900/10 hover:bg-red-100/50 dark:hover:bg-red-800/20';
      }
    };

    const getSentimentTextColor = () => {
      if (isPositive) {
        return 'text-green-900 dark:text-green-100';
      } else if (isNeutral) {
        return 'text-gray-900 dark:text-gray-100';
      } else {
        return 'text-red-900 dark:text-red-100';
      }
    };

    const getSentimentBadgeStyles = () => {
      if (isPositive) {
        return 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-300';
      } else if (isNeutral) {
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900/50 dark:text-gray-300';
      } else {
        return 'bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-300';
      }
    };

    return (
      <a 
        href={news.url} 
        target="_blank" 
        rel="noopener noreferrer" 
        className={`block rounded-lg p-4 transition-colors duration-200 border ${getSentimentStyles()}`}
      >
        <div className="space-y-2">
          <h4 className={`text-sm font-medium ${getSentimentTextColor()}`}>
            {news.title}
          </h4>
          <p className="text-xs text-gray-600 dark:text-gray-300 line-clamp-2">
            {news.text}
          </p>
          <div className="flex items-center gap-2 flex-wrap">
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {formatNewsDate(news.publishedDate)}
            </span>
            <span className="text-xs text-gray-400 dark:text-gray-500">•</span>
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {news.site}
            </span>
            <span className="text-xs text-gray-400 dark:text-gray-500">•</span>
            <span className={`text-xs px-2 py-0.5 rounded-full font-medium ${getSentimentBadgeStyles()}`}>
              {sentiment}
            </span>
          </div>
        </div>
      </a>
    );
  };

  const MetricCard = ({ title, value, change, subValue, isPrice, isVolume, isMarketCap, isBidAsk, isRange, isDividend }) => (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-3 hover:bg-gray-100 dark:hover:bg-gray-700/50 transition-colors duration-200">
      <span className="text-xs font-medium text-gray-500 dark:text-gray-400">
        {title}
      </span>
      <div className="flex items-baseline gap-2 mt-1">
        <span className="text-sm font-semibold text-gray-900 dark:text-gray-100">
          {isPrice && !Array.isArray(value) ? `$${formatNumber(value)}` :
           isVolume ? new Intl.NumberFormat('en-US').format(value) :
           isMarketCap ? formatLargeNumber(value) :
           isBidAsk ? `$${value[0]} × ${value[1]}` :
           isRange ? `$${value[0]} - $${value[1]}` :
           isDividend ? `$${value[0]} (${value[1]})` :
           value}
        </span>
        {change && (
          <span className={`text-xs font-medium px-1.5 py-0.5 rounded
            ${change >= 0 
              ? 'text-green-700 bg-green-50 dark:text-green-400 dark:bg-green-900/30'
              : 'text-red-700 bg-red-50 dark:text-red-400 dark:bg-red-900/30'
            }`}>
            {change >= 0 ? '↑' : '↓'} {formatPercentage(Math.abs(change))}
          </span>
        )}
      </div>
      {subValue && (
        <span className="text-xs text-gray-400 dark:text-gray-500 mt-0.5 block">
          {subValue}
        </span>
      )}
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Company Header with Current Price */}
      <div className="flex flex-col gap-3">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center border-b border-gray-200 dark:border-gray-700 pb-3">
          <div>
            <h2 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-gray-100">
              {companyData.companyName} ({symbol})
            </h2>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
              {companyData.exchange} · {companyData.sector}
            </p>
          </div>
          
          <div className={`px-3 py-1 rounded-full text-sm font-medium
            ${marketStatus.isOpen 
              ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
              : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
            }`}
          >
            {marketStatus.isOpen ? 'Market Open' : 'Market Closed'}
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end gap-2">
            <div className="flex items-baseline gap-3">
              <span className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-gray-100">
                ${formatNumber(companyData.price)}
              </span>
              <span className={`text-base sm:text-lg font-medium
                ${companyData.changesPercentage >= 0 
                  ? 'text-green-600 dark:text-green-400'
                  : 'text-red-600 dark:text-red-400'
                }`}
              >
                {companyData.changesPercentage >= 0 ? '+' : ''}{formatNumber(companyData.change)} 
                ({companyData.changesPercentage >= 0 ? '+' : ''}{formatPercentage(companyData.changesPercentage)})
              </span>
            </div>
            <div className="text-xs text-gray-500 dark:text-gray-400">
              As of {new Date().toLocaleTimeString()}
            </div>
          </div>
        </div>
      </div>

      {/* Main Metrics Grid */}
      <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
        <div className="flex flex-col gap-6">
          {/* Trading Metrics */}
          <div>
            <h3 className="text-xs font-semibold text-gray-500 dark:text-gray-400 mb-3 px-1">
              Trading Information
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3">
              {[
                {
                  title: "Previous Close",
                  value: companyData.previousClose,
                  isPrice: true
                },
                {
                  title: "Open",
                  value: companyData.open,
                  isPrice: true
                },
                {
                  title: "Bid",
                  value: companyData.bid && companyData.bidSize ? 
                    [formatNumber(companyData.bid), companyData.bidSize] : null,
                  isPrice: true,
                  isBidAsk: true
                },
                {
                  title: "Ask",
                  value: companyData.ask && companyData.askSize ? 
                    [formatNumber(companyData.ask), companyData.askSize] : null,
                  isPrice: true,
                  isBidAsk: true
                }
              ].filter(metric => metric.value != null).map((metric, index) => (
                <MetricCard key={index} {...metric} />
              ))}
            </div>
          </div>

          {/* Price Ranges */}
          <div>
            <h3 className="text-xs font-semibold text-gray-500 dark:text-gray-400 mb-3 px-1">
              Price Ranges
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
              {[
                {
                  title: "Day's Range",
                  value: companyData.dayLow && companyData.dayHigh ? 
                    [formatNumber(companyData.dayLow), formatNumber(companyData.dayHigh)] : null,
                  isPrice: true,
                  isRange: true
                },
                {
                  title: "52 Week Range",
                  value: companyData.yearLow && companyData.yearHigh ? 
                    [formatNumber(companyData.yearLow), formatNumber(companyData.yearHigh)] : null,
                  isPrice: true,
                  isRange: true
                }
              ].filter(metric => metric.value != null).map((metric, index) => (
                <MetricCard key={index} {...metric} />
              ))}
            </div>
          </div>

          {/* Volume and Market Stats */}
          <div>
            <h3 className="text-xs font-semibold text-gray-500 dark:text-gray-400 mb-3 px-1">
              Volume & Market Stats
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3">
              {[
                {
                  title: "Volume",
                  value: companyData.volume,
                  isVolume: true,
                  change: ((companyData.volume / companyData.avgVolume - 1) * 100)
                },
                {
                  title: "Avg. Volume",
                  value: companyData.avgVolume,
                  isVolume: true
                },
                {
                  title: "Market Cap",
                  value: companyData.marketCap,
                  isMarketCap: true,
                  subValue: "(intraday)"
                },
                {
                  title: "Beta",
                  value: companyData.beta?.toFixed(2),
                  subValue: "(5Y Monthly)"
                }
              ].filter(metric => metric.value != null).map((metric, index) => (
                <MetricCard key={index} {...metric} />
              ))}
            </div>
          </div>

          {/* Financial Metrics */}
          <div>
            <h3 className="text-xs font-semibold text-gray-500 dark:text-gray-400 mb-3 px-1">
              Financial Metrics
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3">
              {[
                {
                  title: "PE Ratio",
                  value: companyData.peRatio?.toFixed(2),
                  subValue: "(TTM)"
                },
                {
                  title: "EPS",
                  value: companyData.eps,
                  isPrice: true,
                  subValue: "(TTM)"
                },
                {
                  title: "Forward Dividend & Yield",
                  value: companyData.lastDiv ? 
                    [formatNumber(companyData.lastDiv), formatPercentage(companyData.dividendYield)] : null,
                  isDividend: true
                },
                {
                  title: "1y Target Est",
                  value: companyData.targetPrice,
                  isPrice: true
                }
              ].filter(metric => metric.value != null).map((metric, index) => (
                <MetricCard key={index} {...metric} />
              ))}
            </div>
          </div>
        </div>
      </div>


      {/* Performance Metrics */}
      {/* Price Performance */}
      <div className="bg-white dark:bg-gray-800 rounded-lg p-4">
        <h3 className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-3">
          Price Performance
        </h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-2">
          {[
            { label: '1D', value: companyData?.changesPercentage },
            { label: '5D', value: companyData?.['5D'] },
            { label: '1M', value: companyData?.['1M'] },
            { label: '6M', value: companyData?.['6M'] },
            { label: '1Y', value: companyData?.['1Y'] },
            { label: 'MAX', value: companyData?.['MAX'] }
          ].map((period) => (
            <PricePerformanceCard 
              key={period.label} 
              label={period.label} 
              value={period.value}
              isLoading={loading} 
            />
          ))}
        </div>
      </div>

      {/* Company Info */}
      <div className="space-y-4">
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
            <span className="text-sm text-gray-500 dark:text-gray-400">Sector</span>
            <p className="mt-1 font-medium text-gray-900 dark:text-gray-100">{companyData?.sector || 'N/A'}</p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
            <span className="text-sm text-gray-500 dark:text-gray-400">Industry</span>
            <p className="mt-1 font-medium text-gray-900 dark:text-gray-100">{companyData?.industry || 'N/A'}</p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
            <span className="text-sm text-gray-500 dark:text-gray-400">Exchange</span>
            <p className="mt-1 font-medium text-gray-900 dark:text-gray-100">{companyData?.exchange || 'N/A'}</p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
            <span className="text-sm text-gray-500 dark:text-gray-400">Currency</span>
            <p className="mt-1 font-medium text-gray-900 dark:text-gray-100">{companyData?.currency || 'N/A'}</p>
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100 mb-2">
            About {companyData?.companyName}
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-300 leading-relaxed">
            {companyData?.description || 'No description available.'}
          </p>
        </div>
      </div>

      {/* Add Company News section before the closing div */}
      <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
          Company News
        </h3>
        <div className="space-y-3">
          {loading ? (
            // Loading skeleton for news
            Array(3).fill(0).map((_, i) => (
              <div key={i} className="animate-pulse">
                <div className="h-32 bg-gray-100 dark:bg-gray-700 rounded-lg"></div>
              </div>
            ))
          ) : uniqueNewsData.length > 0 ? (
            uniqueNewsData.map((news, index) => (
              <NewsCard key={`${news.title}-${index}`} news={news} />
            ))
          ) : (
            <p className="text-gray-500 dark:text-gray-400 text-center py-4">
              No recent news available
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;